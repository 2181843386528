$gutter: 40px;



//TYPOGRAPHY

  // LABEUR MENU

      //SM
      $xs-labeur-fs: 19px;
      $xs-labeur-lh: 21px;
      $xs-labeur-ti: 10px;

        // MD
      $labeur-fs: 25px;
      $labeur-lh: 27px;
      $labeur-ti: 10px;

  // TEXTE COURANT

      // SM
      $xs-common-fs: 11px;
      $xs-common-lh: 13px;
      $xs-common-ti: 20px;

      // MD
      $common-fs: 14px;
      $common-lh: 16px;
      $common-ti: 20px;
