.nav{
  width: 100%;
  position: fixed;
  z-index: 999;
  ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  padding: 1.2rem;
  @include mq(md){
    padding: 1.875rem;
  }
}

.menu{
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition-duration-default $transition-timing-default;
  &.show{
    opacity: 1;
    transition: opacity $transition-duration-default $transition-timing-default;
    pointer-events: auto;
  }
}

.site-title{
  opacity: 0;
  pointer-events: none;
  @include font(h1);
  position: fixed;
  bottom: 0.3em;
  text-align: center;
  width: 100%;
  left:0;
  font-size: 14vw;
  line-height: 0.9em;
  transition: opacity $transition-duration-default $transition-timing-default;
  &.show{
    opacity: 1;
    transition: opacity $transition-duration-default $transition-timing-default;
  }
  span{
    display: block;
  }
  @include mq(md){
    font-size: 6.25rem;
    span{
      display: inline;
    };
  }
}

.active{
  text-decoration: underline !important;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 0.025em !important;
}

.overlay--opened{
  a[href="#about"], a[href="#contact"]{
    display: none;
    @include mq(md){
      display: block;
    }
  }
}

.overlay{
  position: fixed;
  overflow: hidden;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  z-index: 99;
  padding-top: 2.7em;
  background-color: var(--bkg-color);
  opacity: 0;
  transition: opacity $transition-duration-default $transition-timing-default;
  pointer-events: none;
  &.show{
    opacity: 1;
    transition: opacity $transition-duration-default $transition-timing-default;
    pointer-events: auto;
  }
  @include mq(md){
    padding-top: 6.5rem;
  }
}

.overlay-inner{
  width: 100%;
  padding: 1.875rem;
  padding-top: 0rem !important;
  @include mq(md){
    padding-top: 5rem !important;
  }
  overflow-y: scroll;
  height: 100%;
  text-align: left;
  p{
    margin-bottom: 1em;
  }
  button{
    padding: 0;
  }
  @include mq(md){
    padding: 0 18%;
    text-align: center;
  }
  a{
    text-decoration: underline !important;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 0.025em !important;
    &.no-dec{
      text-decoration: none !important;
    }
  }
}

.toggle-overlay{
  position: fixed;
  bottom: 1.2rem;
  @include mq(md){
    position: static;
    bottom: 1.875rem;
  }
}

.display-sm a[href="#about"]{
  right: 1.2rem;
  @include mq(md){
    right: 1.875rem;
  }
}

.display-sm{
  display: block;
  @include mq(md){
    display: none;
  }
}

.display-md{
  display: none;
  @include mq(md){
    display: block;
  }
}

.contact-section-sm{
  margin-top: 1em;
  @include mq(md){
    display: none;
  }
}