// CONFIGURATE DOCUMENT

$fontsize: (
        main: (
                sm: (
                        fs: 24px,
                        lh: 1.1em,
                ),
                md: (
                        fs: 37px,
                        lh: 1.1em,
                ),
        ),
);

$margin: (
        sm: 1,
);

:root {
  font-size: 16px;
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
        main: (
                name: baikal-var-condensed-semi-bold,
                fallback: "Helvetica, helvetica-bq-medium, sans-serif",
                withFile: true,
        ),
        h1: (
                name: helvetica-bq-medium,
                fallback: "baikal-var-regular, helvetica, sans-serif",
                withFile: true,
        ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
        sm: 0px,
        md: 900px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
