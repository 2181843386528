// SM BREAKPOINT
@media (max-width: 768px) {

  // RESET

  .show-mobile{
    display: inline-block !important;
  }

  .show-desktop{
    display: none !important;
  }


  // HOME PAGE

  .grid-item {
    width: 100%;
  }

  .grid{
    margin-top: 0%;
  }

  .grid-item:nth-of-type(2){
    margin-top: 0vw;
  }
  .grid-item:nth-of-type(3){
    margin-top: 0vw;
  }



  // PROJECT PAGE

    #project-page main{
      padding: 0px;
    }


    .show-text{
      display: inline-block;
    }

    .hide-infos{
      display: inline-block;
    }

    .project-text-wrap{
      position: fixed;
      padding-bottom: 18px;
      top:97px;
      height: calc(100% - 97px);
      background-color: white;
      overflow-y: scroll;
      opacity: 0;
      width: 100%;
    }

    .project-text{
      max-width: 430px;
      padding-right: 50px;
    }

    .supinfos{
      padding-top: 0px;
      max-width: 430px;
      padding-right: 50px;
      top: 97px;
      position: fixed;
    }

    .project-infos-wrap{
      display: block;
      width: 100% !important;
      max-width: 100%;
      height: 100%;
      margin-left: 0px;
      top: 0;
      left:0;
      z-index:9;
      padding: 16px 20px 0;
    }

    .project-text, .project-supinfos{
      position: relative;
      top:0;
      left:0;
    }

    .project-infos-wrap, .supinfos{
      padding-right: 50px;
    }

    .project-text{
      margin-top: 0;
      p{
        text-indent: $xs-common-ti;
      }
    }

    .project-supinfos{
      display: none;
    }

    .project-images-wrap{
      padding: 0px 0px 100px 0;
      min-height: 0;
      position: relative;
      bottom: -50px;
      overflow-y: hidden;
      overflow-x: scroll;
      .image-wrap.landscape{
        width: 90vw;
        img{
          width: 100%;
          height: auto !important;
        }
      }

      .image-wrap.portrait{
        max-height: 90vw;
      }
    }

  .infos-wrap{
    display: none !important;
  }

  .footer-infos{
    display: flex;
    padding: 0 20px;
    width: 100%;
    justify-content: space-between;
    a{
      margin: 0;
      font-size: 2em;
    }
    p{
      font-size: 2em;
    }
  }

  .logo-wrap {
    .letter{
      height: 16em;
    }
    #D {
      left: -1.5em;
      top: 0.3em;
    }

    #E {
      position: fixed;
      right: -2.9em;
      top: -0.6em;
    }

    #J {
      position: fixed;
      left: -2.8em;
      bottom: 2.8em;
    }

    #A {
      position: fixed;
      right: 0;
      bottom: 4em;
    }
  }

}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px),
only screen and (min-device-width: 1024px)
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {

  .grid {
    transition: opacity 0.2s ease-in-out;
    margin-top: 95px;
  }

  .grid-sizer {
    width: 50%;
  }

  .grid-item:nth-of-type(2){
    margin-top: 11.25vw;
  }
  .grid-item:nth-of-type(3){
    margin-top: 5vw;
  }

  .grid-item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc($gutter / 2);
    img {
      width: 100%;
      z-index: 2;
    }
    .large.landscape {
      width: 100%;
    }
    .medium.landscape {
      width: 75%;
    }
    .large.portrait {
      width: 75%;
    }
    .medium.portrait {
      width: 55%;
    }
  }

  .grid {
    margin-top: 0px !important;
  }

  .supinfos {
    top: 72px;
  }

  .project-images-wrap {
    padding: 0 0 105px 0;
    .image-wrap {
      height: 100%;
    }
  }
}
