.block-type-collapse{
  &:first-child{
    margin-top: 0;
  }
  &:last-of-type{
    margin-bottom: 2em;
  }
}

.collapsible {
  &.active{
    text-decoration: none !important;
    &:hover{
      text-decoration: none;
    }
  }
  margin-bottom: 1em;
  position: relative;
  text-align: center;
  color: inherit;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  @include padding(0.4 0);
  @include mq(md){
    margin-bottom: 0;
  }
}

/* Style the collapsible content. Note: hidden by default */
.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s $transition-timing-default;
  &.collapsed{
    @include mq(md){
      transition: max-height  0.5s $transition-timing-default;
    }
  }
}
.collapsible + .content{
  overflow: scroll;
}
.content-inner{
  @include padding(0.4 0 0.4 0);
  columns: 1;
  @include mq(md) {
    &[data-columns="one"] {
      columns: 1;
    }
    &[data-columns="two"] {
      columns: 2;
      column-fill: balance;
      padding-bottom: 0.4em;
      page-break-inside: avoid;
      break-inside: avoid;
      -webkit-column-break-inside: avoid;
    }
  }
  ul{
    margin-bottom: 1em;
  }
  li{
    text-align: left;
    position: relative;
    padding-left: 3.4rem;
    @include mq(md){
      padding-left: 50%;
    }
  }
  p{
    margin-bottom: 1em;
    &:last-of-type{
      margin-bottom: 0.2em;
    }
  }
  a{
    display: inline-block;
    margin-left: 0.5em;
  }
  a.underline{
    margin-left: 0em;
  }
  a.hover{
    margin-left: 0;
    &:hover{
    }
  }
  a + br + a.underline{
    text-indent: 0 !important;
  }
  a + a.underline{
    text-indent: 0 !important;
  }

  h1{
    display: block;
    font-size: inherit;
    font-weight: normal;
  }

}


.table{
  max-width: 100%;
  @include mq(md){
    max-width: 50%;
  }
  & + p{
    margin-top: 1em;
  }
  .row{
    display: grid;
    grid-template-areas: "a a";
    grid-auto-columns: 1fr 4em;
    @include mq(md){
      grid-auto-columns: 80% 4em;
    }
    p{
      margin-bottom: 0;
    }
  }
  a{
  }
}

.collapsible:before {
  content:"[+]";
  margin-left: 0px;
  height: 100%;
  display: inline-block;
  top:-0.1em;
  margin-right: 1em;
  align-items: center;
  @include mq(md){
    margin-left: 5px;
    content: none;
  }

}

.collapsible.active:before {
  content: "[–]"; /* Unicode character for "minus" sign (-) */
  height: 100%;
  margin-left: 0px;
  vertical-align: baseline;
  @include mq(md){
    margin-left: 5px;
    content: none;
  }
}

// /005B
// /005B