// PROJECT INFOS

#project-page #logo{
  display: none !important;
}

#project-page main{
  padding: 0px;
  overflow-y: hidden;
  
}

.quit.home{
  display: block !important;
  z-index: 99;
}

.blank{
  position: absolute;
  left: 0;
  top:0;
  background-color: white;
  height: 100vh;
  width: 45vw;
  opacity: 0;
  pointer-events: none !important;
  transition: opacity 0.2s ease-in-out;
}

.blank.appear{
  z-index: 2;
}

.project-infos-wrap{
  height: 100%;
  pointer-events: none;
  position: fixed;
  margin-left: $gutter - 4px;
  top:76px;
  max-width: 400px;
}

.project-text{
  margin-top: 15px;
  transition: opacity 0.2s ease-in-out;
  p{
    text-indent: $common-ti;
  }
  p:first-of-type{
    text-indent: 0;
  }
}


.project-text-wrap{
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

// BUTTONS

.show-text{
  display: none;
  pointer-events: auto !important;
}

.show-infos.hide{
  pointer-events: none;
}

.hide-infos{
  display: none;
}

.show-text.open:after{
  content:"Masquer le texte";
}

.show-text:after{
  content:"Afficher le texte";
}

.show-text span:before{
  content:"[+] ";
}
.show-text.open span:before{
  content:"[×] ";
}



.project-supinfos-wrap{
  margin-top: 14px;
  p:before{
    content:"— ";
  }
}

.supinfos{
  padding-top: 12px;
  opacity: 0;
  transition-delay: 250ms;
  transition: 0s ease-in-out;
  background-color: white;
  pointer-events: none;
  user-select: none;
  div{
    margin-bottom: 13px;
  }
}

.supinfos.appear{
  user-select: auto;
}

.hide{
  opacity: 0 !important;
  transition: 0.2 ease-in-out;
}

.project-images-wrap{
  padding: 93px 0 68px 50vw;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  width: auto;
  height: 100%;
  min-height: 600px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;

  .image-wrap:last-of-type{
    margin-right: 10px;
  }

  .image-wrap{
    margin-right: 4px;
    height: 100%;
    max-height: 100%;
    opacity: 0;
    transition: 0.2s ease-in-out;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    img{
      height: 100%;
      max-height: 100%;
      width: auto;
    }
  }
}