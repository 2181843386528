// RESET

*::-webkit-scrollbar{
  display: none;
}

*:focus {outline:0;}

.overflow-hidden{
  overflow: hidden !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

p,h1,h2,h3,h4,h5,h6{
  margin:0;
}

ul{
  list-style-type: none;
  padding: 0;
}

a{
  color: inherit;
  text-decoration: none;
}

a:hover{
  color: inherit;
  text-decoration: none;
}

a.underline:hover{
  @media (hover: hover) {
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 0.025em;
  }
}

.td-none{
  box-shadow: none !important;
}

.td-undl{
  box-shadow: inset 0 -1px 0 0 black;
}

.show-mobile{
  display: none !important;
}

html, body, .page{
  height:100%;
  width: 100%;
  padding:0;
  margin:0;
  overflow: hidden;
  position: fixed;
  background: var(--bkg-color);
  color: var(--txt-color);
  @include font(main);
  @include fs(main);
}

main{
  top:0;
  left:0;
  height:100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 1.2rem;
  @include mq(md){
    padding: 1.875rem;
  }
}